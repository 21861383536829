/* istanbul ignore file */
import getHCStorageUtil from '@hc-frontend/shared-assets/src/js/sem/storage-manager/getHCStorage';
import storage from 'proxy-storage';

let hcStorage;

const getHCStorage = () => {
  if (!hcStorage) {
    hcStorage = getHCStorageUtil();
  }

  return hcStorage;
};

const setHCStorage = hc => {
  hcStorage = hc;
  storage.setItem('hc', hcStorage);
};

export {getHCStorage, setHCStorage};
